// import { ApplyPageProps } from 'pages/job/[listing]/apply/old-apply';
import {
  ExperienceCardProps,
  InfoCardProps,
  JobListingProps,
  TableProps,
} from 'components';
import { mapQuestion } from 'components/Application/questions';
import { EducationCardProps } from 'components/InfoCard/EducationCard';
import { LicenceCardProps } from 'components/InfoCard/LicenceCard';
import { JobCardProps } from 'components/JobCard';
import { DirectoryItem } from 'components/pages/Directory';
import { TableJobsProps } from 'components/pages/PortalEmployer';
import {
  ApplicationRow,
  TableApplicationsProps,
} from 'components/pages/PortalEmployer/Tables/Applications';
import { TraniningPartnersProps } from 'components/pages/Training';
import { PartnerDescriptionProps, TestimonialProps } from 'components/sections';
import { PartnerItemProps } from 'components/sections/Partners/PartnerItem';
import { TableRowProps } from 'components/Table/Row';
import {
  Enum_Category_Enum,
  Enum_Job_Status_Enum,
  Enum_Listing_Enum,
  User,
} from 'database/types';
import { EmployerPageProps } from 'pages/directory/[slug]';
import { JobPageProps } from 'pages/job/[listing]';
import { JobResult } from 'types/utils';

export const samplePartnerList: PartnerItemProps[] = [
  {
    partnerName: 'Partner 1',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 2',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 3',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 4',
    parnerWebsite: 'http://#',
  },
];

export const samplePartnerDescription: PartnerDescriptionProps = {
  partnerSubtitle: 'Our Partner Promise',
  partnerTitle: 'We are proud to partner with the best.',
  partnerDescription:
    'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.',
};

export const sampleTestimonial: TestimonialProps = {
  quoteTitle:
    'Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutp at, diam. Montes, magna cursus nulla feugiat dignissim id lobortis amet.',
  quoteAuthor: 'Cos Bruyn, CEO at Company Name',
};

export const samplejobSlug = 'floor-sander-at-company-830458wejf0s9dfj03';
export const samplejobSlug2 = 'floor-sander-2-at-company-9w83ujfo2i3u0w9e8fj';
export const samplejobSlug3 = 'floor-sander-3-at-company-0924utjp';

export const sampleJobCard: JobCardProps = {
  id: 'asdfasd',
  title: 'Qualified Builder',
  slug: 'asdasdasd',
  employer: {
    description: 'Fun and challenging workplace',
    name: 'Superior Construction Ltd ',
    logo: '/assets/images/logos/tradeJobsBlack.svg',
    slug: 'superior',
  },
  list_date: new Date().toISOString(),
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  // categories: ['Trades & Services', 'Flooring'], //#TODO change DB back to text[]
  category: {
    label: 'Joinery',
  },
  location: {
    district: 'Waitakere',
    region: 'Auckland',
  },
  work_type: {
    label: 'Full-time',
  },
  tagline:
    'Looking for a Qualified and experienced builder of 6+ years to join our team. You must be well rounded in all areas or building, confident running a and supervising a team.',
  highlights: [
    //#TODO maybe change DB to text[]
    'Fun and challenging workplace',
    'Attractive overtime benefits',
    'Sponsorship for qualification courses',
  ],
  isNew: false,
  listing_type: 'standard',
};

export const sampleJobListing: any = {
  ...sampleJobCard,
  id: samplejobSlug,
  slug: samplejobSlug,
  pay_high: 30,
  pay_low: 20,
  pay_type: 'hourly',
  show_pay: true,
  about:
    'Looking for a Qualified and experienced builder of 6+ years to join our team. You must be well rounded in all areas or building, confident running a and supervising a team.',
  requirements: [
    'Valid NZ Full Drivers Licence and ability to work in NZ',
    'Qualified in NZQA Carpentry',
    'Strong Health & Safety Practices',
    'Have there own Tools',
    'Own wheelbarrow is preferable',
    'Sense of Humour',
  ],
};

export const sampleJobCard2: JobCardProps = {
  ...sampleJobCard,
  title: 'Qualified Electrician',
};
export const sampleJobListing2: JobListingProps = {
  ...sampleJobListing,
  ...sampleJobCard2,
  id: samplejobSlug2,
};

export const sampleJobCard3: JobCardProps = {
  ...sampleJobCard,
  title: 'Auto Mechanic',
};

export const sampleJobListing3: JobListingProps = {
  ...sampleJobListing,
  ...sampleJobCard3,
  id: samplejobSlug3,
};

export const sampleInfoCard: InfoCardProps = {
  id: 's908dgjsop',
  title: 'Job title',
  subtitle: 'Company Name',
  corner_info: 'Jun 2017 - present',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem tristique quis ac nisi pellentesque netus tincidunt. Ornare molestie enim in ultrices. Commodo, viverra ac egestas ullamcorper purus lectus. ',
};

export const sampleExperienceCard: ExperienceCardProps = {
  id: '2093tufjwpo',
  title: 'Job title',
  company: 'Company Name',
  start_date: new Date('2019-01-02').toISOString(),
  current: true,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem tristique quis ac nisi pellentesque netus tincidunt. Ornare molestie enim in ultrices. Commodo, viverra ac egestas ullamcorper purus lectus. ',
};

export const sampleExperienceCard2: ExperienceCardProps = {
  id: 'rw0g93joks',
  title: 'Job title 2',
  company: 'Company Name 2',
  current: false,
  start_date: new Date('2016-01-02').toISOString(),
  end_date: new Date('2018-11-02').toISOString(),
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem tristique quis ac nisi pellentesque netus tincidunt. Ornare molestie enim in ultrices. Commodo, viverra ac egestas ullamcorper purus lectus. ',
};
export const sampleEducationCard: EducationCardProps = {
  id: 'rw0g93joks',
  title: 'Electrician',
  institution: 'Awesome Trade School',
  issue_date: new Date('2016-01-02').toISOString(),
  complete: true,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem tristique quis ac nisi pellentesque netus tincidunt. Ornare molestie enim in ultrices. Commodo, viverra ac egestas ullamcorper purus lectus. ',
};

export const sampleLicenceCard: LicenceCardProps = {
  id: 'rw0g93joks',
  title: 'Registered Electrician',
  organisation: 'Electrical Workers Registration Board',
  issue_date: new Date('2016-01-02').toISOString(),
  expires: false,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sem tristique quis ac nisi pellentesque netus tincidunt. Ornare molestie enim in ultrices. Commodo, viverra ac egestas ullamcorper purus lectus. ',
};

export const sampleCategories = [
  {
    title: 'Automotive',
    longTitle: 'Automotive Trades',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'automotive',
  },
  {
    title: 'Construction',
    longTitle: 'Construction & Building',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'construction',
  },
  {
    title: 'Electrical',
    longTitle: 'Electrical & Air Conditioning',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'electrical',
  },
  {
    title: 'Engineering',
    longTitle: 'Engineering & Surveying',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'engineering',
  },
  {
    title: 'Joinery',
    longTitle: 'Joinery & Cabinet Making',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'joinery',
  },
  {
    title: 'Landscaping',
    longTitle: 'Landscaping & Gardening',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'landscaping',
  },
  {
    title: 'Painting',
    longTitle: 'Painting & Decorating',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'painting',
  },
  {
    title: 'Plumbing',
    longTitle: 'Plumbing & Gasfitting',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'plumbing',
  },
  {
    title: 'Roading',
    longTitle: 'Roading & Transport',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'roading',
  },
  {
    title: 'Management',
    longTitle: 'Supervision & Management',
    img: '',
    count: Math.ceil(Math.random() * 100),
    slug: 'supervision',
  },
];

export const sampleCategorySlugs: Enum_Category_Enum[] = [
  'automotive',
  'construction',
  'electrical',
  'engineering',
  'joinery',
  'landscaping',
  'painting',
  'plumbing',
  'roading',
  'fabrication',
];

export const sampleResults: JobResult[] = [
  { ...sampleJobListing, listing_type: 'featured', isNew: true } as JobResult,
  { ...sampleJobListing2, listing_type: 'basic', isNew: true } as JobResult,
  sampleJobListing3 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing3 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing3 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing3 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
  sampleJobListing2 as JobResult,
  sampleJobListing as JobResult,
];

export const sampleEmployerPageData: EmployerPageProps = {
  banner: '/assets/images/employer-banner.jpg',
  companyInfo: {
    employees: 7000,
    founded: 1999,
    industry: 'Construction',
    location: 'New Zealand',
    logo: '/assets/images/logos/mico-black.png',
    name: 'Mico',
    website: 'www.dubdubdubnz.co.nz',
  },
  testimonial: sampleTestimonial,
  results: sampleResults,
};

export const sampleJobResult: JobResult = {
  ...sampleJobCard,
  ...sampleJobListing,
};

export const sampleApplyPageData: any = {
  listing: sampleJobResult,
};

export const sampleJobPageData: JobPageProps = {
  listing: sampleJobResult,
};

// LEGAL

// TERMS of Service

export const sampleTerms = `
<h1>Terms of Service</h1>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

<h2>Header 2</h2>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

<h2>Header 2</h2>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</p>

<h3>Header 3</h3>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?</p>

<h3>Header 3</h3>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?</p>

`;

// PRIVACY POLICY

export const samplePrivacy = `
<h1>Privacy Policy</h1>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

<h2>Header 2</h2>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

<h2>Header 2</h2>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</p>

<h3>Header 3</h3>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?</p>

<h3>Header 3</h3>

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?</p>

`;

// TRAINING PAGE

export const samplePartnerList2: PartnerItemProps[] = [
  {
    partnerName: 'Partner 21',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 22',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 23',
    parnerWebsite: 'http://#',
  },
  {
    partnerName: 'Partner 24',
    parnerWebsite: 'http://#',
  },
];

const sampleTrainingPartnersContent: TraniningPartnersProps = {
  title: 'Category Title',
  description:
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?',
  location: 'Location',
  img: '/assets/images/training.jpg',
  partnerList: samplePartnerList,
};

const sampleTrainingPartnersContent2: TraniningPartnersProps = {
  title: 'Category Title 2',
  description:
    'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos?',
  location: 'Location 2',
  img: '/assets/images/worker.png',
  partnerList: samplePartnerList2,
};

export const sampleTrainingPartners: Record<string, TraniningPartnersProps> =
  Object.values(Enum_Category_Enum).reduce(
    (result, value, i: number) => ({
      ...result,
      [value]:
        i % 2 === 0
          ? sampleTrainingPartnersContent
          : sampleTrainingPartnersContent2,
    }),
    {}
  );

export const NZCities = [
  'Akaroa',
  'Alexandra',
  'Algies Bay',
  'Amberley',
  'Arrowtown',
  'Ashburton',
  'Ashhurst',
  'Auckland',
  'Balclutha',
  'Big Omaha',
  'Blenheim',
  'Bluff',
  'Brightwater',
  'Bulls',
  'Burnham',
  'Cable Bay',
  'Cable Bay Nelson District',
  'Cambridge',
  'Carterton',
  'Cheviot',
  'Christchurch',
  'Clarks Beach',
  'Clinton',
  'Clive',
  'Clyde',
  'Coopers Beach',
  'Coromandel',
  'Cromwell',
  'Dannevirke',
  'Darfield',
  'Dargaville',
  'Doyleston',
  'Drury',
  'Dunedin',
  'Edendale',
  'Edgecumbe',
  'Eketahuna',
  'Eltham',
  'Fairlie',
  'Featherston',
  'Feilding',
  'Foxton',
  'Geraldine',
  'Gisborne',
  'Gore',
  'Granity',
  'Greymouth',
  'Greytown',
  'Hamilton',
  'Hampden',
  'Hanmer Springs',
  'Hastings',
  'Haumoana',
  'Havelock',
  'Havelock North',
  'Hawera',
  'Hector',
  'Helensville',
  'Hikurangi',
  'Hokitika',
  'Huntly',
  'Inglewood',
  'Invercargill',
  'Kaiapoi',
  'Kaikohe',
  'Kaikoura',
  'Kairaki',
  'Kaitaia',
  'Kaitangata',
  'Karangahake',
  'Katikati',
  'Kawakawa',
  'Kawerau',
  'Kerikeri',
  'Kingston',
  'Kirwee',
  'Kumeu',
  'Kurow',
  'Lansdowne',
  'Lawrence',
  'Leeston',
  'Levin',
  'Lincoln',
  'Lower Hutt',
  'Lumsden',
  'Lyttelton',
  'Makara Beach',
  'Mamaku',
  'Manaia',
  'Mangakino',
  'Mangawhai',
  'Mangonui',
  'Mapua',
  'Marlborough Sounds',
  'Martinborough',
  'Marton',
  'Masterton',
  'Matamata',
  'Mataura',
  'Maungaturoto',
  'Methven',
  'Milford Sound',
  'Milton',
  'Moerewa',
  'Morrinsville',
  'Mosgiel',
  'Motueka',
  'Mount Maunganui',
  'Mount Pleasant',
  'Murchison',
  'Murupara',
  'Napier',
  'Nelson',
  'New Plymouth',
  'Ngaruawahia',
  'Ngatea',
  'Nightcaps',
  'Oakura',
  'Oamaru',
  'Ohai',
  'Ohakune',
  'Ohaupo',
  'Ohope',
  'Ohura',
  'Okato',
  'Omarama',
  'Omokoroa',
  'Ongarue',
  'Opotiki',
  'Opua',
  'Opunake',
  'Orewa',
  'Otaki',
  'Otane',
  'Otautau',
  'Otematata',
  'Otorohanga',
  'Outram',
  'Owaka',
  'Oxford',
  'Paekakariki',
  'Paeroa',
  'Pahiatua',
  'Paihia',
  'Palmerston',
  'Palmerston North',
  'Papakura',
  'Papamoa',
  'Paraparaumu',
  'Pareora',
  'Patea',
  'Pauanui',
  'Picton',
  'Piopio',
  'Pirongia',
  'Pleasant Point',
  'Porirua',
  'Prebbleton',
  'Puhi Puhi',
  'Pukekohe',
  'Putaruru',
  'Queenstown',
  'Raetihi',
  'Raglan',
  'Rakaia',
  'Ranfurly',
  'Rangiora',
  'Red Beach',
  'Reefton',
  'Renwick',
  'Richmond',
  'Riverhead',
  'Riverton',
  'Rolleston',
  'Ross',
  'Rotorua',
  'Roxburgh',
  'Ruakaka',
  'Ruatoria',
  'Ruawai',
  'Runanga',
  'Russell',
  'Sanson',
  'Seaward Valley',
  'Seddon',
  'Shannon',
  'Silverdale',
  'Snells Beach',
  'Southbridge',
  'Spring Creek',
  'Springston',
  'Stewart Island',
  'Stirling',
  'Stratford',
  'Taihape',
  'Taipa',
  'Tairua',
  'Takaka',
  'Takanini',
  'Takapau',
  'Taneatua',
  'Tangimoana',
  'Tapanui',
  'Taumarunui',
  'Taupiri',
  'Taupo',
  'Tauranga',
  'Te Anau',
  'Te Aroha',
  'Te Awamutu',
  'Te Awanga',
  'Te Karaka',
  'Te Kauwhata',
  'Te Kuiti',
  'Te Puke',
  'Tekapo Military Camp',
  'Temuka',
  'Thames',
  'The Pines Beach',
  'Timaru',
  'Tirau',
  'Tirohanga Taupo District',
  'Tokoroa',
  'Tongariro Forest Park',
  'Tuakau',
  'Tuatapere',
  'Turangi',
  'Twizel',
  'Upper Hutt',
  'Waharoa',
  'Waiau',
  'Waiheke Island',
  'Waihi',
  'Waihi Beach',
  'Waikanae',
  'Waikari',
  'Waikouaiti',
  'Waikuku Beach',
  'Waimate',
  'Waimauku',
  'Waiouru',
  'Waipawa',
  'Waipu',
  'Waipukurau',
  'Wairoa',
  'Waitara',
  'Waitoa',
  'Waiuku',
  'Wakefield',
  'Wallacetown',
  'Wanaka',
  'Warkworth',
  'Waverley',
  'Wellington',
  'Wellsford',
  'Westport',
  'Whakatane',
  'Whangamata',
  'Whanganui',
  'Whangaparaoa',
  'Whangarei',
  'Whangateau',
  'Whirinaki',
  'Whitianga',
  'Winchester',
  'Winton',
  'Woodend',
  'Woodville',
  'Wyndham',
];

export const NZRegions = [
  'Auckland',
  'Bay of Plenty',
  'Canterbury',
  'Gisborne',
  "Hawke's Bay",
  'Manawatu-Whanganui',
  'Marlborough',
  'Northland',
  'Otago',
  'Southland',
  'Taranaki',
  'Tasman/Nelson',
  'Waikato',
  'Wellington',
  'West Coast',
];

export const sampleUser: Partial<User> = {
  applications: [],
  id: 'sodiufjo3ifj',
  first_name: 'Trady',
  last_name: 'McGoody',
  phone: '20209-0339',
  profile_photo: '/assets/images/avatar1.png',
  role: 'employee',
};

const SampleCell = 'cell cell celllllllllllllll';
const SampleCell2 = 'cell cell';
const sampleRow: TableRowProps = {
  data: [SampleCell, SampleCell2, SampleCell2, SampleCell, SampleCell],
};
const sampleRow2: TableRowProps = {
  data: [SampleCell2, SampleCell2, SampleCell, SampleCell, SampleCell],
};
export const sampleTableData: TableProps = {
  headers: ['Job', 'Location', 'Created', 'Status', 'Applicants'],
  rows: [
    sampleRow,
    sampleRow,
    sampleRow2,
    sampleRow,
    sampleRow,
    sampleRow2,
    sampleRow,
    sampleRow,
  ],
};

export const sampleTableJobs: TableJobsProps['jobs'] = sampleResults.map(
  ({ title, id, location, created_at, list_date }, i) => ({
    id,
    title,
    created_at,
    list_date,
    location: {
      label: location?.region ?? '',
    },
    status: {
      value: Object.keys(Enum_Job_Status_Enum)[i % 3],
      label: Object.values(Enum_Job_Status_Enum)[i % 3],
    },
    employer_id: '093j0293f0',
    applications_aggregate: { aggregate: { count: i } },
    watchlists_aggregate: { aggregate: { count: i } },
    job_views: { views: 1 },
    listing_type: Enum_Listing_Enum.ExpressionOfInterest,
  })
);

const sampleEducationPublic = {
  id: '12c468e0-b7bc-49c2-be79-3c8c21e8dd3d',
  complete: true,
  institution: 'AUT',
  issue_date: '2012-10-01',
  title: 'Software Engineering',
};

const sampleExperiencePublic = {
  id: 'efd30187-4cf9-4699-96e7-9dc4159c6d44',
  company: 'while & for',
  current: true,
  start_date: '2020-05-01',
  end_date: null,
  title: 'Director',
  description:
    "I have been working here for a long time. It is very fun to work here. I like to jog in the weekends, that's a lie!\n",
};

const sampleLicencePublic = {
  id: '4bd25ec2-ca7c-4d56-a747-dfcdc3eef496',
  organisation: 'NZTA',
  expires: false,
  expiry_date: null,
  issue_date: '2020-02-01',
  title: 'Drivers Licence',
};

export const sampleApplication: any = {
  id: 'ccd5b1ed-582a-46da-86d9-0ce7c16ea38a',
  created_at: '2021-08-30T04:44:27.151154+00:00',
  application_status: {
    label: 'Withdrawn',
    value: 'withdrawn',
  },
  questions: [
    {
      question: 'Are you able to legally work in New Zealand?',
      type: 'boolean',
      answers: true,
    },
    {
      question: 'Do you have all of the required qualifications?',
      type: 'boolean',
      answers: true,
    },
    {
      question: 'Do you have all of the required licenses?',
      type: 'boolean',
      answers: true,
    },
  ].map(mapQuestion),
  user: {
    id: 'b8067ef0-a6d7-450a-86b9-a75031a21a8f',
    email: 'regan@whileandfor.com',
    first_name: 'Regan',
    last_name: 'Reihana',
    phone: '+642102784655',
    user_educations: [sampleEducationPublic],
    user_experiences: [sampleExperiencePublic],
    user_licences: [sampleLicencePublic],
  },
  job: {
    id: '4c560f53-8317-462c-85f6-19fd628bc742',
    employer_id: '4c560f53-8317-462c-85f6-19fd628bc742',
    title: 'est sunt irure amet eu',
    applications: [
      {
        id: '6ee427fc-c509-4f3b-8bb0-d89944467bed',
        created_at: '2021-08-30T04:44:27.151154+00:00',
        user: {
          first_name: 'Regan',
          last_name: 'Reihana',
        },
      },
    ],
  },
};

const sampleApplicationRow: ApplicationRow = {
  id: '9f1fc831-f589-454e-84db-5ffb69f73b31',
  created_at: '2021-08-30T04:44:27.151154+00:00',
  application_status: {
    label: 'Applied',
    value: 'applied',
  },
  job: {
    id: '2038tu209t4u2904',
    title: '',
    slug: '',
    employer_id: '2038tu209t4u2904',
    location: { label: '' },
  },
  user: {
    email: '',
    first_name: 'Michaela',
    last_name: 'Getley',
    user_experiences: [],
    user_licences: [],
    user_experiences_aggregate: {
      __typename: undefined,
      aggregate: undefined,
    },
    user_licences_aggregate: {
      __typename: undefined,
      aggregate: undefined,
    },
    user_educations: [],
  },
  job_id: '',
};

const sampleApplicationRow2: ApplicationRow = {
  id: '7574b9b7-4457-4169-aff6-8855c88aed55',
  created_at: '2021-08-30T04:44:27.151154+00:00',
  application_status: {
    label: 'Applied',
    value: 'applied',
  },
  job: {
    employer_id: 'sedfj039jf03930',
    id: '',
    title: '',
    slug: '',
    location: {
      __typename: undefined,
      label: '',
      region: undefined,
    },
  },
  user: {
    email: 'liammunday12@gmail.com',
    first_name: 'Liam',
    last_name: 'Munday',
    user_educations: [],
    user_experiences: [],
    user_licences: [],
    user_experiences_aggregate: {
      __typename: undefined,
      aggregate: undefined,
    },
    user_licences_aggregate: {
      __typename: undefined,
      aggregate: undefined,
    },
  },
  job_id: '',
};

export const sampleTableApplications: TableApplicationsProps['applications'] = [
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
  sampleApplicationRow,
  sampleApplicationRow2,
];

export const sampleBusiness1: DirectoryItem = {
  jobs_aggregate: { aggregate: { count: 10 } },
  logo: '/assets/images/logos/mico-black.png',
  name: 'Mico',
  slug: 'mico',
};
export const sampleBusiness2: DirectoryItem = {
  jobs_aggregate: { aggregate: { count: 10 } },
  logo: '/assets/images/logos/fultonhogan.png',
  location: {
    region: 'Auckland',
  },
  name: 'Fulton Hogan Hogan Hogan Hogan',
  slug: 'barkarama',
};
export const sampleBusiness3: DirectoryItem = {
  jobs_aggregate: { aggregate: { count: 10 } },
  logo: '/assets/images/logos/duncanfloor.png',
  location: {
    region: 'Christchurch',
  },
  name: 'Duncan Floooooooooooooooooooooooooooor',
  slug: 'darwinium',
};

export const sampleBusinesses: DirectoryItem[] = [
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness1,
  sampleBusiness3,
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness3,
  sampleBusiness1,
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness1,
  sampleBusiness3,
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness3,
  sampleBusiness1,
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness1,
  sampleBusiness3,
  sampleBusiness1,
  sampleBusiness2,
  sampleBusiness3,
  sampleBusiness1,
];
